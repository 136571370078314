import { Injectable, Injector, Signal, computed } from '@angular/core';
import { ContextMenuItem } from '@em4cloud/my-cdk';
import { BpmSystemsService, ObjectExplorerItemType, System } from 'app/api';
import { ActionDescriptor } from 'app/core/dialogBuilder/dialog-builder.models';
import { Observable } from 'rxjs';
import { OrgSysAreaService } from '../org-sys-area.service';

@Injectable({ providedIn: 'root' })
export class SysService extends OrgSysAreaService<System, BpmSystemsService> {
    allowedSelectionList: string[] = [];
    contextMenuItems: Signal<ContextMenuItem<any>[]> = computed(() => [
        {
            id: 'createSiblingItem',
            title: this._translocoContent()['workflow.createSiblingItem'],
            isVisible: this.visibilityOfDevelopmentFunctionalities(),
        },
        {
            id: 'createChildItem',
            title: this._translocoContent()['workflow.createChildItem'],
            isVisible: this.visibilityOfDevelopmentFunctionalities(),
        },
        {
            id: 'delete',
            title: this._translocoContent()['delete'],
            isVisible: this.visibilityOfDevelopmentFunctionalities(),
        },
    ]);

    protected override objectExplorerItemType: ObjectExplorerItemType =
        ObjectExplorerItemType.System;

    constructor(inject: Injector) {
        super(inject);
        this._apiService = inject.get(BpmSystemsService);

        this.defaultConstructor();
    }

    override findRootParentNode(id: string): System {
        return this.treeService.findRootParentNode(id);
    }

    protected setUpCreateNewItemActions(): ActionDescriptor[] {
        return [
            {
                code: 'system',
                style: 'icon-button',
                tooltip: this._translocoContent()['createSystem'],
                icon: 'mat_solid:computer',
            },
        ];
    }

    protected canBeDeleted(isRoot: boolean): boolean {
        return true;
    }
    getNodeIcons(nodeType: string): string {
        switch (nodeType) {
            case 'root':
                return 'mat_solid:folder';
            default:
                return 'mat_solid:computer';
        }
    }
    protected handleGetResult(orgId: string, data: [System[]]): void {
        this.filteredItems.set(data[0]);
        this._items.set(data[0]);
    }
    protected createItemWithAPI(item: System, organisation?: string): Observable<System> {
        if (!item.organisation) {
            item.organisation = organisation;
        }
        return this._apiService.createSystem(item);
    }
    protected updateItemWithAPI(items: System[]): Observable<System[]> {
        return this._apiService.updateSystem(items);
    }
    protected deleteItemWithAPI(id: string, organisation?: string): Observable<string> {
        return this._apiService.deleteSystem(id, organisation);
    }
    protected getItemsWithAPI(organisation: string): Observable<System[]> {
        if (!organisation) {
            organisation = localStorage.getItem('organisation');
        }
        return this._apiService.getSystems(organisation);
    }
    protected getActiveItemWithAPI(organisation?: string): Observable<System[]> {
        if (!organisation) {
            organisation = localStorage.getItem('organisation');
        }
        return this._apiService.getSystems(organisation, 'ACTIVE');
    }
    protected activateWithAPI(id: string, versionId: string): Observable<System> {
        return this._apiService.activateSystem(id, versionId);
    }
}
